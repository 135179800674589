import React from 'react'
import { Layout } from '../../components/AppLayout'
import { RDHelmet } from '../../components/RDHelmet'
import { AppHackettRaindropWebinarBanner } from '../../components/AppHackettRaindropWebinarBanner'
import { AppHackettRaindropWebinarRest } from '../../components/AppHackettRaindropWebinarRest'

const HackettRaindropWebinar = () => {
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Raindrop Systems Inc',
    alternateName: 'Raindrop Systems Inc',
    url: 'https://raindrop.com/',
    logo: 'https://storage.googleapis.com/raindroppublic/website_data/raindrop_icon.svg',
    description:
      'Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow.',
    image: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    email: 'info@raindrop.com',
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '408-352-5162', contactType: 'Business', areaServed: 'United States' },
    ],
    address: {
      '@type': 'Corporate',
      addressLocality: ' 226 Airport Parkway Suite 250',
      addressRegion: ' San Jose, California 95110',
      postalCode: '95110',
      addressCountry: 'United States',
    },
    sameAs: [
      'https://www.facebook.com/Raindrop-Systems-Inc-103810868375793',
      'https://twitter.com/saas_raindrop',
      'https://www.instagram.com/raindrop_spendmanagement/?igshid=1mn6sbd70aybs',
      'https://www.linkedin.com/company/raindropsystemsinc/',
    ],
  }
  return (
    <Layout>
      <RDHelmet
        subtitle={'HackettRaindropWebinar'}
        contentDescription="Join The Hackett Group & Raindrop’s visionary 2030 procurement team—a CPO, AI leader & AI Agent Rain—to explore AI’s impact on operating models, roles & human-AI collaboration. Register now!"
        contentKeywords="content='AI in procurement 2030, future of procurement webinar, Hackett Group AI research, procurement operating models, AI agent for procurement, Raindrop AI Agent Rain, CPO skills future, human-AI collaboration, procurement automation trends, AI-powered sourcing'"
        contentOG="AI Powered Source-to-Pay SaaS Platform"
        contentOgDescription="Join The Hackett Group & Raindrop’s visionary 2030 procurement team—a CPO, AI leader & AI Agent Rain—to explore AI’s impact on operating models, roles & human-AI collaboration. Register now!"
        contentOgUrl="https://raindrop.com/resources/HackettRaindropWebinar"
        schemaMarkup={schemaMarkup}
      />
      <AppHackettRaindropWebinarBanner />
      <AppHackettRaindropWebinarRest />
    </Layout>
  )
}

export default HackettRaindropWebinar
