import React from 'react'
import styled from 'styled-components'
import { ReUseHOneTag } from './ReUseHOneTag'
import { ReUsePtag } from './ReUsePtag'

const AppHackettRaindropWebinarBannerWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  height: auto;

  .HackettRaindropWebinarBannerBannerSection {
    height: 40vw;
    display: flex;
    flex-direction: column; /* Stack content vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    padding: 0 80px;
    background-color: #102c64;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23035484' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23047CC2'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
    @media (max-width: 425px) {
      height: 40vh;
    }
  }

  .HackettRaindropWebinarBannerHeading {
    text-align: center;
    margin-top: 20px;
    font-size: 3rem;
    color: #f1f1f1;
    @media (max-width: 1920px) {
      font-size: 3.5rem;
    }
    @media (max-width: 1650px) {
      font-size: 3rem;
    }
    @media (max-width: 1090px) {
      font-size: 2rem;
    }
    @media (max-width: 770px) {
      font-size: 1.5rem;
    }
    @media (max-width: 425px) {
      padding-top: 15px;
      font-size: 1rem;
    }
  }

  .HackettRaindropWebinarBannerPara {
    text-align: center;
    font-size: 1.2rem;
    color: #f1f1f1;
    margin-top: 10px;
    width: 60vw;
    @media (min-width: 1650px) {
      font-size: 1.3rem;
    }
    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
    @media (max-width: 425px) {
      font-size: 0.6rem;
      width: 70vw;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #34a1d5;
    color: white;
    border: none;
    border-radius: 42px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    @media (max-width: 425px) {
      font-size: 0.8rem;
      margin: 0;
    }

    &:hover {
      background-color: #fff;
      color: #111;
    }
  }
`

export const AppHackettRaindropWebinarBanner: React.FC = () => {
  return (
    <AppHackettRaindropWebinarBannerWapper>
      <div className="HackettRaindropWebinarBannerBannerSection">
        <ReUseHOneTag Heading="The Hackett Group/Raindrop Webinar" HeadingStyle="HackettRaindropWebinarBannerHeading" />
        <ReUsePtag
          para="The 2030 Team Interview: AI Agents, CPOs, and Operating Models Reimagined"
          paraStyle="HackettRaindropWebinarBannerPara"
        />
        <button
          onClick={() => {
            window.open(
              'https://storage.googleapis.com/raindroppublic/website_data/2025-CLM-Study-Results-RaindropCarve-Out-Content.pdf',
              '_blank'
            )
          }}
        >
          Download Now
        </button>
      </div>
    </AppHackettRaindropWebinarBannerWapper>
  )
}
