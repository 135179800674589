import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
// import { ReuseImgTag } from './ReuseImgTag'
import { ReUseHOneTag } from './ReUseHOneTag'

const AppHackettRaindropWebinarRestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  width: 96vw;
  .HackettRaindropWebinarRestSection {
    display: flex;
    flex-direction: column; /* Stack content for better responsiveness */
    padding: 50px 20px; /* Add padding for mobile */
  }

  .GartnerReprintDisclaimerHeading {
    font-size: 1.6rem;
    color: #1f1f1f;
    letter-spacing: 1px;
    padding-bottom: 20px;
    @media (min-width: 1650px) {
      font-size: 1.8rem;
    }
    @media (max-width: 1650px) {
      margin-top: 55px;
    }
    @media (max-width: 1090px) {
      padding-top: 35px;
    }
    @media (max-width: 480px) {
      font-size: 42px;
    }
    @media (max-width: 1090px) {
      font-size: 1.2rem;
    }
  }

  .HackettRaindropWebinarRestPara {
    color: #5f5f5f;
    font-size: 1.1rem;
    line-height: 36px;
    padding-bottom: 40px;
    @media (min-width: 1650px) {
      font-size: 1.2rem;
    }
    @media (max-width: 1090px) {
      font-size: 0.9rem;
      padding-top: 8px;
    }
  }

  .HackettRaindropWebinarRestContentSection {
    display: flex;
    flex-direction: column;
  }

  .GartnerReprintDisclaimerHeading,
  .HackettRaindropWebinarRestPara {
    margin: 0 auto;
    max-width: 1920px; /* Restrict text width for readability */
  }
`

export const AppHackettRaindropWebinarRest = () => {
  return (
    <AppHackettRaindropWebinarRestWapper>
      <div className="HackettRaindropWebinarRestSection">
        <div className="HackettRaindropWebinarRestContentSection">
          <ReUsePtag
            para="May 15, 2025 | 10 AM Pacific/1 PM Eastern | 30 minutes."
            paraStyle="HackettRaindropWebinarRestPara "
          />
          <ReUsePtag
            para="Speakers: Kate Denissova - Senior Director of Procurement at The Hackett Group, Ward Karson - Future CPO, Shannon - Future Spend Orchestration Leader - AI Agent - Rain"
            paraStyle="HackettRaindropWebinarRestPara "
          />
          <ReUseHOneTag Heading="Webinar Description" HeadingStyle="GartnerReprintDisclaimerHeading" />
          <ReUsePtag
            para="Get ready to travel a few years into the future—just far enough to see how rapidly evolving AI is transforming procurement and finance teams. In this fun, fast-paced webinar, we’ll sit down with the “future” digital workforce: a visionary Chief Procurement Officer, an AI-empowered Spend Orchestration leader, and Rain, our resident AI agent. Kate Denissova, Senior Director of the Procurement Advisory Practice, will conduct the interviews, digging into how operating models have changed, what new responsibilities have emerged, and what human-AI collaboration really looks like in 2028. Equal parts insightful and imaginative, and maybe a little bit of fun, this conversation will leave you rethinking your talent strategy, your tech stack—and maybe even your job description."
            paraStyle="HackettRaindropWebinarRestPara "
          />
        </div>
      </div>
    </AppHackettRaindropWebinarRestWapper>
  )
}
